import {Component, Input, OnInit} from '@angular/core';
import {NavParams, PopoverController} from "@ionic/angular";

@Component({
  selector: 'app-error',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
  message: string = '';

  constructor(
    public popoverController: PopoverController,
    public navparam: NavParams
  ) {
    this.message = this.navparam.get('message');
  }

  async _is_Click() {
    this.popoverController.dismiss();
  }

  ngOnInit() {
  }

}
