import {Component, OnInit} from '@angular/core';
import {PopoverController} from "@ionic/angular";

@Component({
  selector: 'app-disable',
  templateUrl: './disable.component.html',
  styleUrls: ['./disable.component.scss'],
})
export class DisableComponent implements OnInit {
  title: any;
  isSelect = '';
  reason = [
    {"value": "Misleading or Scam"},
    {"value": "Offensive"},
    {"value": "Violence"},
    {"value": "False Information"},
    {"value": "The author is pretending to be someone else"},
    {"value": "Prohibited Content"},
    {"value": "Spam"},
    {"value": "False News"},
    {"value": "Other"},
  ]

  constructor(private popoverController: PopoverController) {
  }

  ngOnInit() {
  }

  _Is_Select(value: string) {
    this.isSelect = value

  }

  Close() {
    this.popoverController.dismiss({
      action: 'Close'
    })
  }

  actionSave() {
    this.popoverController.dismiss({
      action: 'confirm',
      value: this.isSelect
    })
  }
}
