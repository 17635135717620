import {Component, OnInit} from '@angular/core';
import {GlobalService} from "./_services/services.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  hiddenMenu: boolean;

  constructor(private _globalService: GlobalService) {
    this.hiddenMenu = _globalService.isLogin();
  }

  ngOnInit() {
  }

}
