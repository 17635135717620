import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, NavigationExtras, NavigationEnd} from '@angular/router';
import jwt_decode from 'jwt-decode';
import {PopoverController, ModalController, Platform} from '@ionic/angular';
import {filter} from "rxjs/operators";
import {FormBuilder} from '@angular/forms';
import {AuthService} from "./service/auth.service";
import {_User} from "../../interfaces/user";
import {_Sign} from "../../interfaces/sign";
import {GlobalService} from "../../_services/services.service";
import {StorageService} from "../../_services/storage.service";

@Component({
  selector: 'app-sigin',
  templateUrl: './sigin.component.html',
  styleUrls: ['./sigin.component.scss'],
})
export class SiginPage implements OnInit {

  Option_Login_Register: string = '';
  TextType: string = 'password';
  SigIn: _Sign = {};
  Register: _User = {};
  PasswordConfirm: string = '';
  isDesktop: boolean = false;

  inputForm = this.formBuilder.group({
    mobile: '',
    password: '',
    fullName: '',
    passwordConfirm: '',
  });

  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public platform: Platform,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private serverService: GlobalService,
    private _auth: AuthService,
    private _storageService: StorageService,
  ) {
    this.isDesktop = this.platform.is('desktop')
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
      });
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.Option_Login_Register = this.router.getCurrentNavigation().extras.state.Option;
      } else {
        this.Option_Login_Register = 'Login';
      }
    });
  }

  ngOnInit() {
  }

  async _is_Item_Login_Register_Click(flag) {
    this.Option_Login_Register = flag;
    this.SigIn = {};
    this.Register = {};
    this.PasswordConfirm = '';
    this.inputForm = this.formBuilder.group({
      mobile: '',
      password: '',
      fullName: '',
      passwordConfirm: '',
    });
  }

  async _is_Submit() {
    if (this.Option_Login_Register == 'Login') {
      this._is_Login();
    }
    if (this.Option_Login_Register == 'Register') {
      this._is_Register();
    }
  }

  async _is_Register() {
    if (this.inputForm.value.fullName == '' || this.inputForm.value.fullName == undefined) {
      await this.serverService.Is_Show_MsgStop('Please enter the fullname');
      return;
    }
    if (this.inputForm.value.mobile == '' || this.inputForm.value.mobile == undefined) {
      this.serverService.Is_Show_MsgStop('Please enter the mobile');
      return;
    }
    if (this.inputForm.value.password == '' || this.inputForm.value.password == undefined) {
      this.serverService.Is_Show_MsgStop('Please enter the password');
      return;
    }
    if (this.inputForm.value.password != '' && this.inputForm.value.password != this.inputForm.value.passwordConfirm) {
      this.serverService.Is_Show_MsgStop('Please enter the password confirm');
      return;
    }
    this.Register = {
      fullName: this.inputForm.value.fullName,
      password: this.inputForm.value.password,
      mobile: this.inputForm.value.mobile
    }
    const popover = await this.modalController.create({
      component: ''
      , animated: true
      , cssClass: 'create-new-post-information'
      , showBackdrop: true
      , backdropDismiss: false
    });
    popover.onWillDismiss().then((Res) => {
      if (Res.data['Result'] == 'Ok') {
        this.serverService.Is_LoadingShow();
        this.serverService.PostApi('api/user/register', this.Register).then((result) => {
          if (result['Status'] == 0) {
            this.serverService.Is_Show_MsgStop(result['ErrMsg']);
          } else {
            this.serverService.Is_LoadingHide();
            this.router.navigate(['/sigin'])
          }
        });
      }
    });
    return await popover.present();
  }

  async _is_Forgot_Password() {
    this.router.navigateByUrl('/forgot-password');
  }

  async _is_Login() {
    if (this.inputForm.value.mobile == '' || this.inputForm.value.mobile == undefined) {
      await this.serverService.Is_Show_MsgStop('Please enter the username!');
      return;
    }
    if (this.inputForm.value.password == '' || this.inputForm.value.password == undefined) {
      await this.serverService.Is_Show_MsgStop('Please enter the password!');
      return;
    }
    this.SigIn = {
      mobile: this.inputForm.value.mobile,
      password: this.inputForm.value.password
    }
    this._auth.signIn(this.SigIn).then((result) => {
      if (result['Status'] == 0) {
        this.serverService.Is_Show_MsgStop(result['ErrMsg']);
      } else {
        if(result['Data']['isError']){
          this.serverService.Is_Show_MsgStop(result['Data']['responseException']['exceptionMessage'])
        }else{
          let Obj_User = jwt_decode(result['Data'].result);
          if (Obj_User['Role'] !== 'Admin') {
            this.serverService.Is_Show_MsgStop('Account not role admin. Please login with admin account!')
          } else {
            this._storageService.setItem('login_Token', result['Data'].result)
            this._storageService.setItem('login_Token', result['Data'].result);
            // this._storageService.setItem('login_Id', Obj_User['Id']);
            this._storageService.setItem('login_Id', 'b773d1b3-29b6-4615-79c6-08da1380389b');
            this._storageService.setItem('login_Name', Obj_User['Name']);
            this._storageService.setItem('login_Mobile', Obj_User['Mobile']);
            this._storageService.setItem('login_Username', Obj_User['UserName']);
            this._storageService.setItem('login_Avatar', Obj_User['ProfilePicUrl']);
            this._storageService.setItem('login_Role', Obj_User['Role']);
            if (localStorage.getItem('login_Token')) {
              this.router.navigate(['/']);
            }
          }
        }
      }
    });
  }

  async ShowPassword() {
    if (this.TextType == 'password') {
      this.TextType = 'text';
    } else {
      this.TextType = 'password';
    }
  }
}
