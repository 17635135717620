import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable({
  providedIn: 'root'
})
export class CustomAdapter extends NgbDateAdapter<Date | string> {

  readonly DELIMITER = '-';

  fromModel(value: Date | string | null): NgbDateStruct | null {
    if (value && typeof value === 'string') {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    } else if (value && typeof value === 'object') {
      return {
        day: parseInt(value.getDate().toString(), 10),
        month: parseInt((value.getMonth() + 1).toString(), 10),
        year: parseInt(value.getFullYear().toString(), 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct): Date {
    return new Date(new Date(date.year, date.month - 1, date.day).setHours(23));
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable({
  providedIn: 'root'
})
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}
