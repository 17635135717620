import {Component, OnInit} from '@angular/core';
import {ModalLogoutQuestionPage} from "../modals/modal-logout-question/modal-logout-question.page";
import {ModalController} from "@ionic/angular";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {StorageService} from "../../_services/storage.service";
import {Location} from "@angular/common";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  hidden: boolean = false;
  isChange: boolean = false;
  isShow: boolean = false;

  contentActive: boolean = false;
  contentPostActive: boolean = false;
  contentCommentActive: boolean = false;

  constructor(private _modalController: ModalController, private _router: Router, private _storage: StorageService,
              private _activeRouter: ActivatedRoute, private _location: Location) {
    this._storage.watchStorage().subscribe((data: string) => {
      if (data == 'changed') {
        this.isChange = !this.isChange;
        this.hidden = this._storage.getItem('login_Token') === null;
      }
    })
    this.hidden = this._storage.getItem('login_Token') === null;
  }

  ngOnInit() {
    this._router.events.subscribe((res: NavigationEnd) => {
      if (res instanceof NavigationEnd) {
        if (res.url.indexOf('/content') >= 0) {
          this.contentActive = true;
          this.isShow = true;
          if (res.url.indexOf('/content/post') >= 0) {
            this.contentPostActive = true;
            this.contentCommentActive = false;
          } else {
            this.contentPostActive = false;
            this.contentCommentActive = true;
          }
        } else {
          this.contentActive = false;
          this.contentPostActive = false;
          this.contentCommentActive = false;
          this.isShow = false;
        }
      }
    });
  }

  ngOnChanges() {
    this.hidden = this._storage.getItem('login_Token') === null;
  }

  async _is_Logout() {
    const modal = await this._modalController.create({
      component: ModalLogoutQuestionPage
      , animated: true
      , cssClass: 'app-modal'
      , showBackdrop: true
      , backdropDismiss: false
    });
    modal.onWillDismiss().then((Res) => {
      if (Res.data.res == 'logout') {
        this._storage.removeItem('login_Token');
        this._storage.removeItem('login_Id');
        this._storage.removeItem('login_Name');
        this._storage.removeItem('login_Mobile');
        this._storage.removeItem('login_Avatar');
        this._storage.removeItem('login_Username');
        this._storage.removeItem('login_Role');
        this._router.navigate(['/sigin'])
      }
    });
    return modal.present();
  }

  changeShow() {
    this.isShow = !this.isShow
  }
}
