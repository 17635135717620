import {Injectable} from '@angular/core';
import {LoadingController, PopoverController, ToastController} from "@ionic/angular";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment.prod";
import {ErrorComponent} from "../components/modals/error/error.component";
import {SuccessComponent} from "../components/modals/success/success.component";

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  isLoading = false;

  constructor(
    private popoverController: PopoverController,
    public loadingController: LoadingController,
    private httpClient: HttpClient,
    public toastController: ToastController,
  ) {
  }

  async AlertToast(msg) {
    this.toastController.create({
      message: msg,
      duration: 3000,
      animated: true,
      cssClass: 'alert',
      position: 'bottom',
      color: 'primary',
    }).then((obj) => {
      obj.present();
    });
  }

  async Is_LoadingShow() {
    this.isLoading = true;
    return await this.loadingController.create({
      message: 'Is Loading...',
      spinner: 'circles',
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then();
        }
      });
    });
  }

  async Is_LoadingHide() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then();
  }

  async PostApi(apiUrl, postData): Promise<unknown> {
    this.Is_LoadingShow();
    return new Promise(resolve => {
      var url = environment.apiUrl + apiUrl;
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('login_Token')
        })
      };
      return this.httpClient.post<any>(url, postData, httpOptions)
        .subscribe((response) => {
          let data = {Status: 1, Data: response};
          resolve(data);
          this.Is_LoadingHide();
        }, (err) => {
          let Obj_Error = JSON.parse(JSON.stringify(err));
          let data = {Status: 0, ErrMsg: Obj_Error['error']['responseException'].exceptionMessage};
          resolve(data);
          this.Is_LoadingHide();
        });
    });
  };

  async PostApiNoLoading(apiUrl, postData): Promise<unknown> {
    return new Promise(resolve => {
      var url = environment.apiUrl + apiUrl;
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('login_Token')
        })
      };
      return this.httpClient.post<any>(url, postData, httpOptions)
        .subscribe((response) => {
          let data = {Status: 1, Data: response};
          resolve(data);
        }, (err) => {
          let Obj_Error = JSON.parse(JSON.stringify(err));
          let data = {Status: 0, ErrMsg: Obj_Error['error']['responseException'].exceptionMessage};
          resolve(data);
        });
    });
  };

  isLogin() {
    let token = localStorage.getItem('login_Token')
    return !!token;
  }

  async Is_Show_MsgStop(Message) {
    const popover = await this.popoverController.create({
      component: ErrorComponent,
      cssClass: 'action-modal',
      componentProps: {message: Message},
      showBackdrop: true,
      animated: true
    });
    popover.onWillDismiss();
    return await popover.present();
  }

  async Is_Show_MsgSuccess(Message) {
    const popover = await this.popoverController.create({
      component: SuccessComponent,
      cssClass: 'action-modal',
      componentProps: {message: Message},
      showBackdrop: true,
      animated: true
    });
    popover.onWillDismiss();
    return await popover.present();
  }
}
