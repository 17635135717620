import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {FormBuilder, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ScrollingModule} from '@angular/cdk/scrolling';
import {HttpClientModule} from "@angular/common/http";
import {SiginPage} from "./pages/sigin/sigin.component";
import {MenuComponent} from "./components/menu/menu.component";
import {SortPipe} from './pipe/sort.pipe';
import {FileTransfer} from "@ionic-native/file-transfer/ngx";
import {DisableComponent} from "./components/modals/disable/disable.component";
import {NgbDateAdapter, NgbDateParserFormatter, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {CustomAdapter, CustomDateParserFormatter} from "./_services/common/datepicker-format";
import {LazyLoadImageModule} from 'ng-lazyload-image'; // <-- import it

@NgModule({
  declarations: [AppComponent, SiginPage, MenuComponent, SortPipe, DisableComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, FormsModule, ScrollingModule,
    HttpClientModule, ReactiveFormsModule, NgbModule, LazyLoadImageModule

  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    FormBuilder,
    FileTransfer,
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

