import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {StorageService} from "../_services/storage.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private _storage: StorageService
  ) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const loginState = this._storage.getItem('login_Token')
    if (loginState) {
      // authorised so return true
      return true;
    }
    // not logged in so redirect to login pages with the return url
    this.router.navigate(['/sigin']);
    return false;
  }

}
