import {Injectable} from '@angular/core';
import {GlobalService} from "../../../_services/services.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _globalService: GlobalService) {
  }

  signIn(data) {
    return this._globalService.PostApi('user/sign-in', data)
  }
}
