import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./_guard/auth.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'user',
    pathMatch: 'full'
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard]
  }, {
    path: 'report',
    loadChildren: () => import('./pages/report/report.module').then(m => m.ReportModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'content',
    loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'promotion',
    loadChildren: () => import('./pages/promotion/promotion.module').then(m => m.PromotionModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sigin',
    loadChildren: () => import('./pages/sigin/sigin.module').then(m => m.SiginModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
